import React from "react"

export const FirstStepIcon = () => (
  <>
    <svg
      width="178"
      height="24"
      viewBox="0 0 178 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.04098 12C4.04098 7.58172 7.65936 4 12.1229 4C16.5864 4 20.2048 7.58172 20.2048 12C20.2048 16.4183 16.5864 20 12.1229 20C7.65936 20 4.04098 16.4183 4.04098 12ZM16.835 8.75259C17.252 9.11951 17.2895 9.75158 16.9189 10.1644L11.5309 16.1644C11.3392 16.3779 11.0644 16.5 10.7759 16.5C10.4873 16.5 10.2125 16.3779 10.0208 16.1644L7.32686 13.1644C6.95619 12.7516 6.99375 12.1195 7.41076 11.7526C7.82777 11.3857 8.46631 11.4229 8.83698 11.8356L10.7759 13.9948L15.4087 8.83564C15.7794 8.42285 16.418 8.38567 16.835 8.75259Z"
        fill="#76BC21"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.04098 12C4.04098 7.58172 7.65936 4 12.1229 4C16.5864 4 20.2048 7.58172 20.2048 12C20.2048 16.4183 16.5864 20 12.1229 20C7.65936 20 4.04098 16.4183 4.04098 12ZM12.1229 2C6.54349 2 2.02051 6.47715 2.02051 12C2.02051 17.5229 6.54349 22 12.1229 22C17.7022 22 22.2252 17.5229 22.2252 12C22.2252 6.47715 17.7022 2 12.1229 2Z"
        fill="#76BC21"
      />
      <line x1="21.8105" y1="12.7" x2="80.0001" y2="12.7" stroke="#76BC21" />
      <circle cx="166.402" cy="12.4019" r="9.48187" stroke="#76BC21" strokeWidth="1.04027" />
      <circle cx="166.36" cy="12.3601" r="3.1125" fill="#76BC21" />
      <path
        d="M99.0254 12.3599C99.0254 17.5685 94.7589 21.7998 89.4849 21.7998C84.2109 21.7998 79.9444 17.5685 79.9444 12.3599C79.9444 7.15132 84.2109 2.92004 89.4849 2.92004C94.7589 2.92004 99.0254 7.15132 99.0254 12.3599Z"
        stroke="#76BC21"
        strokeWidth="1.04027"
      />
      <ellipse cx="89.485" cy="12.3601" rx="3.14394" ry="3.1125" fill="#76BC21" />
      <line x1="98.8181" y1="12.7" x2="157" y2="12.7" stroke="#76BC21" />
    </svg>
  </>
)
