import React, { useState, useRef, useCallback, useEffect } from "react"
import { navigate } from "gatsby"

import clsx from "clsx"

import Box from "@material-ui/core/Box"
import MaskedField from "@ecom/ui/components/FormLanding/fields/MaskedField"
import TextField from "@ecom/ui/components/FormLanding/fields/TextField"
import DateField from "@ecom/ui/components/FormLanding/fields/DateField"
import DadataAddressFlat from "@ecom/ui/components/FormLanding/fields/DadataFields/DadataAddressFlat"
import DadataFio from "@ecom/ui/components/FormLanding/fields/DadataFields/DadataFio"
import SwitchField from "@ecom/ui/components/FormLanding/fields/SwitchField"
import RadioGroupField from "@ecom/ui/components/FormLanding/fields/RadioGroupField"
import CheckboxField from "@ecom/ui/components/FormLanding/fields/CheckboxField"
import requiredValidator from "@ecom/ui/components/FormLanding/validators/required"
import lengthValidator from "@ecom/ui/components/FormLanding/validators/lengthValidator"
import GENDERS from "@ecom/ui/enums/genders"
import pushToDataLayer from "@ecom/ui/utils/pushToDataLayer"

import { useLocation } from "@gatsbyjs/reach-router"
import FormControl from "../FormControl"
import { GuardSecuritySvg } from "../../images/svg/Icon"

import type { PassportFormData } from "./types"

import {
  formatValuesToRequest,
  passportDateValidator,
  fetchPassportOrganByCode,
  registrationDateValidator,
} from "./helpers"
import disableWebvisor from "../../utils/disableWebvisor"
import { getManyIDBValues, setIDBValue } from "../../utils/idbUtils"
import { getAddressForDelivery } from "../../helpers/getAddress"

import * as styles from "./passportRedesign.module.scss"

import type { FieldData } from "../../interfaces/fieldData"
import type { ESIAData } from "../../interfaces/esia"
import type { IDadataAddress } from "../../interfaces/dadata"
import { LongAppWrapper } from "../LongAppWrapper"
import { FirstStepIcon } from "../../images/svg/FirstStep"
import { sendRequest } from "../../helpers/sendRequest"
import { events } from "../../helpers/events"
import { HalvaResponse } from "../../interfaces/responseStatus"

function validateByLength(len: number) {
  return (value: string) => lengthValidator(value, len)
}

export default function PassportRedesign() {
  const refForm = useRef<any>(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isDifferentAddress, setIsDifferentAddress] = useState(false)
  const [isChangedCredentials, setIsChangedCredentials] = useState(false)
  const [requestId, setRequestId] = useState("")
  const location = useLocation()

  const [esiaData, setEsiaData] = useState<Partial<ESIAData>>({})
  const {
    birthPlace,
    passportSeries,
    passportCode,
    passportOrgan,
    passportDate,
    registrationAddress,
    homeAddress,
  } = esiaData

  const onChangeFields = {
    birthDate: ({ value }: FieldData<string>) => {
      refForm.current.setField("birthDate", { value })
    },
    changedCredentials: ({ value }: FieldData<boolean>) => {
      refForm.current.setField("oldFio", { isRequired: value })
      setIsChangedCredentials(value)
    },
    differentAddress: ({ value }: FieldData<boolean>) => {
      refForm.current.setField("lifeAddress", { isRequired: !value })
      setIsDifferentAddress(!value)
    },
    passportCode: ({ value }: FieldData<string>) => {
      if (value.length === 7) {
        fetchPassportOrganByCode(value).then((data) => {
          if (data) {
            refForm.current.setField("passportOrgan", { value: data })
          }
        })
      }
    },
  }

  // отключение записи ПД в вебвизоре
  const ref = useCallback((node: any) => {
    disableWebvisor(node)
  }, [])

  useEffect(() => {
    getManyIDBValues(["esiaData", "requestId"]).then(([data, id]) => {
      if (data) {
        setEsiaData(data)
      }
      if (id) {
        setRequestId(id)
      }
    })
  }, [])

  function handleSubmit(values: PassportFormData) {
    pushToDataLayer({
      event: "GAFormEvent",
      name: "Продолжить",
      placement: "appl_halva",
      eventAction: "passportForm",
      eventCategory: "sendForm",
    })
    setIsSubmitting(true)
    const valuesToRequest = formatValuesToRequest(values)
    const regAddressData = getAddressForDelivery(
      values.registrationAddress,
      valuesToRequest.registrationAddress as string
    )

    setIDBValue("registrationAddress", regAddressData)

    if (!values.differentAddress) {
      const lifeAddressData = getAddressForDelivery(
        values.lifeAddress as IDadataAddress,
        valuesToRequest.lifeAddress as string
      )
      setIDBValue("lifeAddress", lifeAddressData)
    }

    sendRequest(`/v2/app/public/halva/${requestId}`, { ...valuesToRequest }, true)
      .then(({ status, apiId }: HalvaResponse) => {
        switch (status) {
          case "reject":
            navigate("/error-reject")
            pushToDataLayer(events.applicationSend.reject)
            return
          case "error":
            throw Error(status)
          default:
            break
        }

        setIDBValue("requestId", apiId)
      })
      .then(() => {
        navigate("/employmentRedesign/", {
          state: {
            birthDate: values.birthDate,
            thanksPage: location.state.thanksPage,
          },
        })
      })
      .catch(() => {
        pushToDataLayer(events.applicationSend.error)
        pushToDataLayer(events.GAFormEvent.error)
        navigate("/error-technical/")
      })
  }

  return (
    <LongAppWrapper
      handleSubmit={handleSubmit}
      name="passportForm"
      onChangeFields={onChangeFields}
      refForm={refForm}
      isDisabled={isSubmitting}
      stepIcon={<FirstStepIcon />}
      withSteps
      variant="custom"
      title={
        <h2 className={clsx(styles.formHead, styles.main)} ref={ref}>
          Вы выбрали доставку курьером
        </h2>
      }
    >
      <div className={styles.longContainerFluid}>
        <div className={styles.wrapGuaranteeSecurity}>
          <GuardSecuritySvg color="#76BC21" />
          <p>Мы гарантируем безопасность и сохранность ваших данных</p>
        </div>
        <h2 className={clsx(styles.indent, styles.formHead)}>
          Заполните данные, в полном соответствии с паспортом
        </h2>
        <Box className={styles.fieldWrapper}>
          <FormControl className={styles.shortField}>
            <MaskedField
              label="Серия и номер"
              name="passportSeriesAndNumber"
              mask="0000 000000"
              validate={validateByLength(11)}
              defaultValue={passportSeries || ""}
              key={passportSeries}
            />
          </FormControl>
          <FormControl className={styles.shortField}>
            <DateField
              label="Дата рождения"
              name="birthDate"
              max={new Date()}
              validAgeMin={18}
              validAgeMax={85}
              className={styles.shortField}
            />
          </FormControl>
        </Box>
        <FormControl>
          <TextField
            label="Место рождения"
            name="birthPlace"
            validate={requiredValidator}
            defaultValue={birthPlace || ""}
            key={birthPlace}
          />
        </FormControl>
        <Box className={styles.fieldWrapper}>
          <FormControl className={styles.shortField}>
            <MaskedField
              label="Код подразделения"
              name="passportCode"
              mask="000-000"
              validate={validateByLength(7)}
              defaultValue={passportCode || ""}
              key={passportCode}
            />
          </FormControl>
          <DateField
            label="Дата выдачи"
            name="passportDate"
            max={new Date()}
            validate={(val: string) =>
              passportDateValidator(val, refForm.current?.state?.fields?.birthDate?.value)
            }
            defaultValue={passportDate || ""}
            key={passportDate}
            className={styles.shortField}
          />
        </Box>
        <FormControl>
          <TextField
            label="Кем выдан"
            name="passportOrgan"
            multiline
            validate={requiredValidator}
            defaultValue={passportOrgan || ""}
            key={passportOrgan}
          />
        </FormControl>
        <div className={styles.radioGroup}>
          <RadioGroupField
            items={GENDERS}
            label="Ваш пол:"
            name="gender"
            defaultValue="m"
            classes={{
              formControlLabel: styles.formControlLabel,
            }}
          />
        </div>
        <SwitchField
          label="Я менял Фамилию, Имя или Отчество "
          name="changedCredentials"
          defaultValue={false}
          validateOnBlur={false}
          classes={{ root: styles.switchField, label: styles.switchLabel }}
        />
        {isChangedCredentials && (
          <div className={styles.oldFioField}>
            <DadataFio label="Фамилия Имя Отчество" name="oldFio" helperText="Предыдущее ФИО" />
          </div>
        )}
        <h2 className={styles.formHead}>Адрес регистрации</h2>
        <FormControl>
          <DadataAddressFlat name="registrationAddress" incomingValue={registrationAddress} />
          <DateField
            max={new Date()}
            label="Дата регистрации"
            name="registrationDate"
            defaultValue=""
            validate={(val: string) =>
              registrationDateValidator(val, refForm.current?.state?.fields?.birthDate?.value)
            }
          />
        </FormControl>
        <h2 className={styles.formHead}>Адрес фактического проживания</h2>
        <CheckboxField
          name="differentAddress"
          label="Фактический адрес проживания совпадает с адресом регистрации"
          color="primary"
          defaultValue={!isDifferentAddress}
          validateOnBlur={false}
          classes={{
            labelClasses: {
              label: styles.checkboxLabel,
            },
          }}
        />
        {isDifferentAddress && (
          <div className={styles.lifeAddress}>
            <DadataAddressFlat name="lifeAddress" incomingValue={homeAddress} />
          </div>
        )}
      </div>
    </LongAppWrapper>
  )
}
