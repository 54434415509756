import { formatDate } from "@ecom/ui/utils/formatters"
import sendRequest from "@ecom/ui/utils/sendRequest"
import formatToRequest from "@ecom/ui/helpers/formatToRequest"

import { getAddress } from "../../helpers/getAddress"

import type { PassportFormData, PassportFormDataFormatted } from "./types"

const fieldsType = {
  passportDate: "date",
  registrationDate: "date",
  oldFio: "fio",
  registrationAddress: "dadataAddressFlat",
  lifeAddress: "dadataAddressFlat",
  birthDate: "date",
}

export function formatValuesToRequest(values: PassportFormData): PassportFormDataFormatted {
  const valuesToRequest = formatToRequest(values, fieldsType)
  const passportNumAndSeries = valuesToRequest.passportSeriesAndNumber

  if (passportNumAndSeries) {
    const splitValue = passportNumAndSeries.split(" ")

    // eslint-disable-next-line prefer-destructuring
    valuesToRequest.passportSeries = splitValue[0]
    // eslint-disable-next-line prefer-destructuring
    valuesToRequest.passportNumber = splitValue[1]
    delete valuesToRequest.passportSeriesAndNumber
  }

  if (valuesToRequest)
    if (valuesToRequest.changedCredentials) {
      const { surname, name, patronymic } = valuesToRequest.oldFio

      valuesToRequest.oldSurname = surname
      valuesToRequest.oldName = name
      valuesToRequest.oldPatronymic = patronymic
      delete valuesToRequest.oldFio
    }

  valuesToRequest.registrationAddress = getAddress(valuesToRequest.registrationAddress)

  if (valuesToRequest.differentAddress) {
    valuesToRequest.lifeAddress = valuesToRequest.registrationAddress
  } else {
    valuesToRequest.lifeAddress = getAddress(valuesToRequest.lifeAddress)
  }

  return valuesToRequest
}

export function passportDateValidator(passportIssuingDate: string, birthDate?: string) {
  if (!passportIssuingDate || passportIssuingDate.length < 10) {
    return "Заполните поле"
  }

  const passportDate = new Date(formatDate(passportIssuingDate))
  const birthdayDate = birthDate ? new Date(formatDate(birthDate)) : passportDate
  const fourTeenYearsOld = birthdayDate.setFullYear(birthdayDate.getFullYear() + 14)

  if (passportDate.getTime() - fourTeenYearsOld < 0) {
    return "Пожалуйста, проверьте дату выдачи паспорта. Для одобрения заявки необходимы корректные данные"
  }

  return null
}

export function registrationDateValidator(registrationDate: string, birthDate?: string) {
  if (!birthDate) return null
  if (!registrationDate || registrationDate.length < 10) return "Заполните поле"

  const formattedRegistrationDate = new Date(formatDate(registrationDate))
  const formattedBirthDate = new Date(formatDate(birthDate))

  return formattedRegistrationDate < formattedBirthDate
    ? "Дата регистрация не может быть раньше даты рождения"
    : null
}

interface Response {
  value: string
}

export function fetchPassportOrganByCode(value: string): Promise<Response["value"]> {
  return sendRequest(`${process.env.GATSBY_APP_API_URL}/v1/cache/passport/issue/`, {
    value,
  }).then((data: Response) => data.value)
}
